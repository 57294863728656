<template>
  <div class="orders-table">
    <v-row
      no-gutters
      class="align-center text-body-2 default--text font-weight-bold mt-5 mb-2"
    >
      <v-col cols="1" class="hidden-sm-and-down">
        <div class="d-flex flex-column flex-lg-row">
          <strong v-html="$t('order.header.orderNumber')"></strong>
        </div>
      </v-col>
      <v-col cols="2" class="hidden-sm-and-down text-center">
        <strong>{{ $t("order.header.orderType") }}</strong>
      </v-col>
      <v-col cols="2" class="hidden-sm-and-down">
        <strong>{{ $t("order.header.orderDate") }}</strong>
      </v-col>
      <v-col cols="3" md="1">
        <strong>{{ $t("order.header.orderStatus") }}</strong>
      </v-col>
      <v-col cols="7" sm="5" md="3">
        <strong>{{ $t("order.header.orderAddress") }}</strong>
      </v-col>
      <v-col cols="2" class="text-center">
        <strong>{{ $t("order.header.orderTotalAmount") }}</strong>
      </v-col>
      <v-col cols="1" class="hidden-sm-and-down"> </v-col>
    </v-row>

    <v-list>
      <v-list-item
        v-for="order in orders"
        :key="order.orderId"
        v-bind:to="'/profile/orders/' + order.orderId"
      >
        <!-- :class="{ zebra: index % 2 == 0 }" -->
        <v-row no-gutters align="center">
          <v-col cols="1" class="hidden-sm-and-down">
            <strong class="order-id">#{{ order.orderId }}</strong>
          </v-col>
          <v-col cols="2" class="hidden-sm-and-down text-center">
            <img
              v-if="order.orderTypeId == 1"
              style="width: 60px; margin: 0 auto;"
              :src="
                $t(
                  `navbar.service.${order.shippingAddress.deliveryServiceId}.iconNoLabel`
                )
              "
            />
            <img
              v-else-if="order.orderTypeId == 2"
              style="width: 60px; margin: 0 auto;"
              src="/img_layout/services_icons/spesa-veloce.svg"
            />
          </v-col>
          <v-col cols="2" class="default--text hidden-sm-and-down">
            <span>
              {{ $dayjs(order.timeslot.date).format("D MMMM YYYY") }}
            </span>
          </v-col>
          <v-col cols="3" md="1">
            <OrderStatusBadge v-bind:order="order" :disabled="true" />
          </v-col>
          <v-col cols="7" sm="5" md="3">
            <span class="text-capitalize d-flex flex-column">
              <span
                class="default--text"
                v-html="
                  $t(
                    `navbar.address.${order.shippingAddress.addressTypeId}.ordersList`,
                    order.shippingAddress
                  )
                "
              >
              </span>
              <!-- <span v-if="order.orderTypeId == 1" class="default--text">
                {{
                  $dayjs(
                    order.timeslot.date + " " + order.timeslot.beginTime,
                    "YYYY-MM-DD hh:mm:ss A"
                  ).format("D MMMM | HH:mm")
                }}
              </span>
              <span v-else class="default--text">
                {{
                  $dayjs(
                    order.timeslot.date + " " + order.timeslot.beginTime,
                    "YYYY-MM-DD hh:mm:ss A"
                  ).format("D MMMM")
                }}
              </span> -->
            </span>
          </v-col>
          <v-col cols="2" class="text-center">
            <OrderGrossTotal :item="order"></OrderGrossTotal>
          </v-col>
          <v-col
            cols="1"
            class="actions-order d-sm-flex justify-end hidden-sm-and-down "
          >
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
                  outlined
                  @click.stop.prevent="editOrder(order.orderId)"
                  class="rounded-sm ml-3"
                  v-if="order.isEditable"
                >
                  <v-icon small class="default--text">$edit</v-icon>
                </v-btn>
              </template>
              <span>Modifica ordine</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  class="rounded-sm ml-3"
                  @click.stop.prevent="deleteOrder(order.orderId)"
                  v-if="order.isDeletable"
                >
                  <v-icon small class="default--text">$delete</v-icon>
                </v-btn>
              </template>
              <span>Annulla ordine</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  large
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="main-button rounded-sm ml-3 primary"
                  v-if="order.orderStatusId == 8 || order.orderStatusId == 10"
                  @click.stop.prevent="addAllToCart(order.orderId)"
                >
                  <v-icon small class="default--text">$cart</v-icon>
                </v-btn>
              </template>
              <span>Aggiungi tutto al carrello</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<style scoped lang="scss">
.orders-table {
  .v-list {
    background-color: transparent;
    .v-list-item {
      background-color: $white;
      border: 1px solid var(--v-grey-lighten1);
    }
  }
}
.ali-per-me-img {
  max-width: 100%;
}

.we-love-trees-img h3 {
  text-align: center;
  color: var(--v-white-base);
}
.we-love-trees-img p {
  text-align: center;
  color: var(--v-white-base);
  padding-bottom: 2px;
}
.we-love-trees-img .v-btn.v-btn--outlined {
  border-color: #ffffff;
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge";
import OrderGrossTotal from "@/components/orders/OrderGrossTotal";
import login from "~/mixins/login";

// import OrderService from "~/service/orderService";
import { mapState, mapGetters } from "vuex";
export default {
  name: "OrdersTable",
  mixins: [login],
  props: ["orders"],
  components: {
    OrderStatusBadge,
    OrderGrossTotal
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    timeslot() {
      return this.getItemByProductId(this.product.productId);
    },
    quantity() {
      return this.item ? this.item.quantity : 0;
    }
    /*canIRateOrder() {
      return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    }*/
  },
  methods: {
    delivered(order) {
      return (
        order.orderStatusId == 6 ||
        order.orderStatusId == 8 ||
        order.orderStatusId == 18
      );
    },
    rateOrder(orderId) {
      this.$router.push("/order/" + orderId);
    },
    async deleteOrder(orderId) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });
      if (res) {
        this.$emit("delete", orderId);
      }
    },
    async editOrder(orderId) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("order.editOrder", {
          orderId: orderId
        }),
        width: 575
      });
      if (res) {
        this.$emit("editOrder", orderId);
      }
    },
    async addAllToCart(orderId) {
      if (await this.needLogin("addAllToCart")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            this.$emit("addAllToCart", orderId);
          }
        }
      }
    }
  },
  mounted() {}
};
</script>
