var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orders-table"},[_c('v-row',{staticClass:"align-center text-body-2 default--text font-weight-bold mt-5 mb-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"cols":"1"}},[_c('div',{staticClass:"d-flex flex-column flex-lg-row"},[_c('strong',{domProps:{"innerHTML":_vm._s(_vm.$t('order.header.orderNumber'))}})])]),_c('v-col',{staticClass:"hidden-sm-and-down text-center",attrs:{"cols":"2"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("order.header.orderType")))])]),_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"cols":"2"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("order.header.orderDate")))])]),_c('v-col',{attrs:{"cols":"3","md":"1"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("order.header.orderStatus")))])]),_c('v-col',{attrs:{"cols":"7","sm":"5","md":"3"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("order.header.orderAddress")))])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("order.header.orderTotalAmount")))])]),_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"cols":"1"}})],1),_c('v-list',_vm._l((_vm.orders),function(order){return _c('v-list-item',{key:order.orderId,attrs:{"to":'/profile/orders/' + order.orderId}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"cols":"1"}},[_c('strong',{staticClass:"order-id"},[_vm._v("#"+_vm._s(order.orderId))])]),_c('v-col',{staticClass:"hidden-sm-and-down text-center",attrs:{"cols":"2"}},[(order.orderTypeId == 1)?_c('img',{staticStyle:{"width":"60px","margin":"0 auto"},attrs:{"src":_vm.$t(
                `navbar.service.${order.shippingAddress.deliveryServiceId}.iconNoLabel`
              )}}):(order.orderTypeId == 2)?_c('img',{staticStyle:{"width":"60px","margin":"0 auto"},attrs:{"src":"/img_layout/services_icons/spesa-veloce.svg"}}):_vm._e()]),_c('v-col',{staticClass:"default--text hidden-sm-and-down",attrs:{"cols":"2"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$dayjs(order.timeslot.date).format("D MMMM YYYY"))+" ")])]),_c('v-col',{attrs:{"cols":"3","md":"1"}},[_c('OrderStatusBadge',{attrs:{"order":order,"disabled":true}})],1),_c('v-col',{attrs:{"cols":"7","sm":"5","md":"3"}},[_c('span',{staticClass:"text-capitalize d-flex flex-column"},[_c('span',{staticClass:"default--text",domProps:{"innerHTML":_vm._s(
                _vm.$t(
                  `navbar.address.${order.shippingAddress.addressTypeId}.ordersList`,
                  order.shippingAddress
                )
              )}})])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_c('OrderGrossTotal',{attrs:{"item":order}})],1),_c('v-col',{staticClass:"actions-order d-sm-flex justify-end hidden-sm-and-down",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(order.isEditable)?_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-sm ml-3",attrs:{"large":"","icon":"","outlined":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.editOrder(order.orderId)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"default--text",attrs:{"small":""}},[_vm._v("$edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Modifica ordine")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(order.isDeletable)?_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-sm ml-3",attrs:{"icon":"","large":"","outlined":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteOrder(order.orderId)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"default--text",attrs:{"small":""}},[_vm._v("$delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Annulla ordine")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(order.orderStatusId == 8 || order.orderStatusId == 10)?_c('v-btn',_vm._g(_vm._b({staticClass:"main-button rounded-sm ml-3 primary",attrs:{"large":"","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addAllToCart(order.orderId)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"default--text",attrs:{"small":""}},[_vm._v("$cart")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Aggiungi tutto al carrello")])])],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }